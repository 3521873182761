/**
 * Created by Sergey Panpurin on 12/18/2017.
 */

(function btRealTimeChartClosure() {
  'use strict';

  var gDebug = false;

  angular
    .module('dashboard')
    /**
     * ???
     *
     * @ngdoc directive
     * @name btRealTimeChart
     * @memberOf ecapp
     */
    .directive('btRealTimeChart', directive);

  directive.$inject = ['$templateCache', '$interval', 'btDateService'];

  /**
   *
   * @param {angular.ITemplateCacheService} $templateCache
   * @param {angular.IIntervalService} $interval
   * @param {ecapp.IDateService} btDateService
   * @return {angular.IDirective}
   */
  function directive($templateCache, $interval, btDateService) {
    return {
      restrict: 'E',
      scope: {
        symbol: '@',
        interval: '@',
        hideSideToolbar: '@?',
        skipTimezone: '@?',
      },
      template: $templateCache.get('dashboard/directives/common/bt-real-time-chart.html'),
      controller: controller,
      link: function LinkFn(scope, elem, attr, ctrl) {
        void attr;
        void ctrl;

        if (gDebug) {
          console.log('btRealTimeChart linked');
          console.log('Check real time chart ' + elem[0].querySelector('#' + scope.id));
          console.log('Check real time chart ' + elem[0].parentElement);
        }

        scope.intervalHandler = $interval(loadTradingView, 100, 0, false);

        var timezone = localStorage.getItem('btChartTimeZone') || 'Etc/UTC';

        /**
         *
         */
        function loadTradingView() {
          if (gDebug) console.log('Interval was called (loadTradingView) - ' + scope.id);
          if ($('#' + scope.id).length) {
            var params = {
              // eslint-disable-line no-new
              autosize: true,
              symbol: 'OANDA:' + scope.symbol.replace('_', ''),
              interval: scope.interval,
              timezone: timezone,
              theme: 'Dark',
              style: '1',
              locale: 'en',
              toolbar_bg: '#f1f3f6',
              enable_publishing: false,
              allow_symbol_change: false,
              hideideas: true,
              hide_side_toolbar: scope.hideSideToolbarValue,
              show_popup_button: true,
              popup_width: '1000',
              popup_height: '650',
              container_id: scope.id,
            };

            if (scope.skipTimezone === 'true') {
              params['timezone'] = btDateService.suggestTradingViewTimeZone();
            }

            new window.TradingView.widget(params);
            $interval.cancel(scope.intervalHandler);
          }
        }
      },
    };
  }

  controller.$inject = ['$scope', '$interval'];

  /**
   *
   * @param {*} $scope
   * @param {angular.IIntervalService} $interval
   */
  function controller($scope, $interval) {
    console.log('btRealTimeChart created');

    /**
     *
     * @param {*} min
     * @param {*} max
     * @return {any}
     */
    function getRandomInt(min, max) {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min)) + min; //The maximum is exclusive and the minimum is inclusive
    }

    $scope.id = 'real-time-' + getRandomInt(0, 9999999);

    $scope.hideSideToolbarValue = ($scope.hideSideToolbar || 'true') === 'true';
    $scope.$on('$destroy', function () {
      console.log('btRealTimeChart destroyed');
      $interval.cancel($scope.intervalHandler);
    });
  }
})();
